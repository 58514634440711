import React, { useEffect, useState, useRef } from "react";
import "./Create_Complaint.scss";
import {
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
    TextField,
    IconButton,
    Tooltip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../../../Utilities/Utility";
import {
    tableHeaderStyle,
    tableOptionsUser,
} from "../../../../Themes/LightTheme";
import axios from "axios";
import Loader from "../../../../components/Loader/Loader";
import DownloadIconOutlined from "@material-ui/icons/GetApp";
import SnackbarCustom from "../../../../components/Snackbar/Snackbar";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import OnlinePactNavBar from "../OnlinePactNavBar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { deleteTempAttchments } from "../../../../components/CustomComponents/CustomComponents";

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    accordioncolor: {
        background: "#D1FFF2",
    },
    accordionMargin: {
        marginTop: "10px",
    },
}));

export default function CreateComplaint() {
    const [selectedFile, setSelectedFile] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    //Snackbar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    //For View file
    const [fileData, setFileData] = useState([]);

    // const [selectedDate, setSelectedDate] = useState(new Date());
    const [inputFileValue, setInputFileValue] = useState(Date.now);

    const updateExcelHandler = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        setSelectedFile(chosenFiles);
        console.log("SelectedFile", e.target.files);
    };

    const referenceTypeData = [
        {
            id: 1,
            value: "Invoice",
        },
        {
            id: 2,
            value: "Misc. Document/Reasons",
        },
    ];

    const columns = [
        /* { title: "Invoice Number", field: "InvoicePosition", editable: "never" },//bil_doc = Invoice Number*/
        { title: "Invoice Position", field: "Item", editable: "never" }, //bil_item = Invoice Position
        { title: "MLFB", field: "MLFB", editable: "never" },
        { title: "Quantity", field: "Quantity", editable: "never" },
        { title: "Order No", field: "OrderNo", editable: "never" },
        { title: "Order Item", field: "OrderItem", editable: "never" },
        { title: "BSS", field: "BSS", editable: "never" },
        { title: "BU", field: "BU", editable: "never" },
        { title: "Affected MLFB", field: "AffectedMLFB" },
        { title: "Affected Qty", field: "AffectedQuantity" },
    ];

    const [sampleTableData, setSampleTableData] = useState([]);
    const [tempAttachemntId, setTempAttachemntId] = useState(0);
    const [attachmentGroupID, setAttachmentGroupID] = useState("");
    const [inputFieldVal, setInputFieldVal] = useState({
        rollNumber: userDetails.rollNumber, // get dynamic master data from customer
        userName: userDetails.firstName + " " + userDetails.lastName,
        department: userDetails.departmentName, // should look up with dept master
        phoneNumber: userDetails.phoneNumber,
        salesOffice: userDetails.officeName,
        email: userDetails.email,
        gid: userDetails.gid,
        userId: userDetails.loginId,
        OfficeId: userDetails.salesOffice,
        // check default value
        referenceNumber: "",
        invoiceNumber: "", //9019602907
        reason: "",
        dispatchCenter: "",
        customer: "",
        division: "",
        transporterName: "",
        lrNo: "",
        lrDate: "",
        seEmail: "",
        seMultiEmail: [],
        remarks: "",
        //  Document: null,
        invoiceDetails: sampleTableData,
        FileGroupingId: attachmentGroupID,
    });
    const [seMultiEmails, setSeMultiEmails] = useState([]);

    const inputFieldsChange = (e) => {
        const fieldVal = e.target.value;
        const fieldName = e.target.name;
        setInputFieldVal((inputFieldVal) => ({
            ...inputFieldVal,
            [fieldName]: fieldVal,
        }));
    };

    const searchInvoice = () => {
        console.log("Invoice Number", inputFieldVal.invoiceNumber);
        setIsLoading(true);
        getInvoiceDetails();
        // setShowTable(true);
    };

    const onSubmit = () => {
        const formData = new FormData();
        // setIsLoading(true);

        for (let i = 0; i < selectedFile.length; i++) {
            if (selectedFile[i].size <= 20971520) {
                formData.append("Document", selectedFile[i]);
            } else if (
                selectedFile.type == "application/octet-stream" ||
                selectedFile.type == "application/x-msdownload"
            ) {
                setSelectedFile("");
                handleSnackOpen("Invalid File Format.", "error");
                console.log("Invalid File Type", selectedFile.type);
            } else {
                setIsLoading(false);
                setSelectedFile("");
                handleSnackOpen("File Size Exceeded.", "error");
                console.log(
                    "Exceed file size || Invalid File format",
                    selectedFile.size
                );
            }
        }

        if (selectedFile.length > 0) {
            setIsLoading(true);
            createCompApi(formData);
        } else {
            setIsLoading(false);
            handleSnackOpen("Select files to Upload", "warning");
        }
    };

    function createCompApi(formDataArgs) {
        api
            .post("/UploadComplaintFile/" + userDetails.loginId, formDataArgs)
            .then(function (response) {
                setTempAttachemntId(response.data[0].attachmetId);
                setAttachmentGroupID(response.data[0].complaintFileMappingId);
                setIsLoading(false);
                setSelectedFile("");
                setFileData(response.data);
                setInputFieldVal((inputFieldVal) => ({
                    ...inputFieldVal,
                    complaintFileMappingId: response.data[0].complaintFileMappingId,
                }));
                setIsLoading(false);
                handleSnackOpen("File Uploaded Successfully.", "success");

                console.log("Uploaded File Details", response.data);
            })
            .catch(function (error) {
                setIsLoading(false);
                handleSnackOpen("Error in saving.", "error");
                console.log(error);
            });
        setIsFileUploaded(true);
    }
    const saveComplaint = () => {
        let hasAffectedMLFBQtyVal = false;

        if (inputFieldVal.userName === "" || inputFieldVal.userName == undefined) {
            handleSnackOpen("Name is Empty", "error");
            return;
        }
        if (
            inputFieldVal.department === "" ||
            inputFieldVal.department == undefined
        ) {
            handleSnackOpen("Department is Empty", "error");
            return;
        }
        if (
            inputFieldVal.salesOffice === "" ||
            inputFieldVal.salesOffice == undefined
        ) {
            handleSnackOpen("Sales Office is Empty", "error");
            return;
        }
        if (inputFieldVal.email === "" || inputFieldVal.email == undefined) {
            handleSnackOpen("Email is Empty", "error");
            return;
        }

        if (inputFieldVal.reason === "" || inputFieldVal.reason == undefined) {
            handleSnackOpen("Reason is Empty", "error");
            return;
        }

        if (
            inputFieldVal.dispatchCenter === "" ||
            inputFieldVal.dispatchCenter == undefined
        ) {
            handleSnackOpen("Dispatch Center is Empty", "error");
            return;
        }

        if (
            seMultiEmails == [] ||
            seMultiEmails == undefined ||
            seMultiEmails.length <= 0
        ) {
            handleSnackOpen("Sales Engineer Mail is Empty", "error");
            return;
        }

        if (inputFieldVal.remarks === "" || inputFieldVal.remarks == undefined) {
            handleSnackOpen("Remarks is Empty", "error");
            return;
        }

        if (
            inputFieldVal.referenceNumber === "" ||
            inputFieldVal.referenceNumber == undefined
        ) {
            handleSnackOpen("Reference Number is Empty", "error");
            return;
        }

        // Only for Invoice Option
        if (inputFieldVal.referenceNumber == 1) {
            if (
                inputFieldVal.invoiceNumber === "" ||
                inputFieldVal.invoiceNumber == undefined
            ) {
                handleSnackOpen("Invoice Number is Empty", "error");
                return;
            }

            // mandatory check for Affected MLFB and Qty for atleast one row
            const tableRows = inputFieldVal.invoiceDetails;
            //const noOfRows = tableRows?.length;
            for(let item of tableRows) {
                if ((item.AffectedMLFB != undefined && item.AffectedMLFB != "") &&
                    (item.AffectedQuantity != undefined && item.AffectedQuantity != "")) {
                    hasAffectedMLFBQtyVal = true;
                    break;
                }
                else { hasAffectedMLFBQtyVal = false; }
            }
            if (!hasAffectedMLFBQtyVal) {
                handleSnackOpen("Enter Affected MLFB and Affected Qty in atleast one record of the table.", "error");
                return;
            }
        }

        console.log("Input Fields Value", inputFieldVal);
        let tblComplaintDetail = {};
        let MlfbConfiguration = {};
        let invoiceDetailsEntered = [];
        if (invoiceMlfbDetails != [])
            invoiceMlfbDetails.forEach(element => {
                if (element.AffectedMLFB != "" || element.AffectedQuantity != "")
                    invoiceDetailsEntered.push(element);
            });
        setIsLoading(true);
        tblComplaintDetail = {
            RollNumber:
                inputFieldVal.rollNumber != null ||
                    inputFieldVal.rollNumber != undefined
                    ? inputFieldVal.rollNumber.toString()
                    : inputFieldVal.rollNumber,
            Name: inputFieldVal.userName.toString(),
            DepartmentName: inputFieldVal.department.toString(),
            PhoneNumber:
                inputFieldVal.phoneNumber != null ||
                    inputFieldVal.phoneNumber != undefined
                    ? inputFieldVal.phoneNumber.toString()
                    : inputFieldVal.phoneNumber,
            SalesOffice: inputFieldVal.salesOffice.toString(),
            Email: inputFieldVal.email.toString(),
            GID: inputFieldVal.gid.toString(),
            InvoicePosition: inputFieldVal.invoiceNumber.toString(),
            Reason: parseInt(inputFieldVal.reason),
            DispatchCenter: parseInt(inputFieldVal.dispatchCenter),
            CustomerName: inputFieldVal.customer,
            ReferenceNumber: inputFieldVal.referenceNumber.toString(),
            InvoiceNo: inputFieldVal.invoiceNumber,
            CreatedBy: inputFieldVal.userId,
            division: inputFieldVal.division,
            transporter: inputFieldVal.transporterName,
            lrno: inputFieldVal.lrNo,
            OfficeId: inputFieldVal.OfficeId,
            // lrDate: new Date(),
            lrdate: inputFieldVal.lrDate,
            seemail: seMultiEmails,
            // seemail: inputFieldVal.seEmail,
            Remarks: inputFieldVal.remarks,
            // Document: selectedFile,
            TempAttachemntId: tempAttachemntId,
            // make dynamic table data here
            InvoiceDetails: invoiceDetailsEntered,
            InvoiceDetailsEmail: invoiceDetailsEntered,
            FileGroupingId: attachmentGroupID,
            AttachmentTable: fileData
        };

        api
            .post("/CreateComplaint", tblComplaintDetail)
            .then(function (response) {
                setIsLoading(false);
                handleSnackOpen(
                    "Complaint Saved Successfully. Complaint No : " +
                    response.data.complaintNumber,
                    "success"
                );
                resetComplaintForm();
                // history.goBack();
                console.log("Motor Details", response.data);
            })
            .catch(function (error) {
                handleSnackOpen("Error in saving.", "error");
                setIsLoading(false);
                console.log(error);
            });

        // setIsLoading(false);
    };

    const resetComplaintForm = () => {
        setInputFieldVal({
            rollNumber: userDetails.rollNumber,
            userName: userDetails.firstName,
            department: userDetails.departmentName,
            phoneNumber: userDetails.phoneNumber,
            salesOffice: userDetails.officeName,
            email: userDetails.email,
            gid: userDetails.gid,
            userId: userDetails.loginId,
            OfficeId: userDetails.salesOffice,
            invoiceNumber: "",
            reason: "",
            dispatchCenter: "",
            customer: "",
            division: "",
            transporterName: "",
            lrNo: "",
            lrDate: "",
            seEmail: "",
            remarks: "",
        });
        deleteTempAttchments(userDetails.loginId);
        setSampleTableData([]);
        setInvoiceMlfbDetails([]);
        setIsFileUploaded(false);
        setSeMultiEmails([]);
        setSelectedFile("");
        setInputFileValue(Date.now);
        setShowTable(false);
        setFileData([]);
    };
    const api = axios.create({
        baseURL: "Complaint",
    });

    function getMasterData() {
        getReasonMaster();
        getDispatchCentreMaster();
    }

    // const deleteTempAttchments = () => {
    //     api
    //         .delete("/deleteAttachmentsBasedOnLoginId/" + userDetails.loginId)
    //         .then((res) => {
    //             console.log("Temp attachments deleted", res);
    //         })
    //         .catch((error) => {
    //             console.log("Error in deleting temp attchments", error);
    //         });
    // };
    const deleteAttachment = (oldData, resolve, reject) => {
        console.log("Old Data");
        api
            .delete("/deleteAttach/" + oldData.attachmetId)
            .then((res) => {
                const dataDelete = [...fileData];
                const index = oldData.attachmetId;
                dataDelete.splice(index, 1);
                setFileData([...dataDelete]);
                reject();
                getFilesFromId(userDetails.loginId);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
                handleSnackOpen("Error while deleting file.", "error");
                setIsLoading(false);
                reject();
            });
    };
    const getFilesFromId = (loginId) => {
        api
            .get("/getAttachList/" + loginId)
            .then((res) => {
                setFileData(res.data);
                handleSnackOpen("File deleted successfully.", "success");
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
                handleSnackOpen("Error while deleting file.", "error");
                setIsLoading(false);
            });
    };
    const [reasonMasterData, setReasonMasterData] = useState([]);
    const getReasonMaster = () => {
        setIsLoading(true);
        api
            .get("/getReasonmaster")
            .then((res) => {
                setReasonMasterData(res.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
                setIsLoading(false);
            });
    };

    const [dispatchMasterData, setDispatchMasterData] = useState([]);
    const getDispatchCentreMaster = () => {
        api
            .get("/getDispatchcentremaster")
            .then((res) => {
                setDispatchMasterData(res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };

    const [invoiceMlfbDetails, setInvoiceMlfbDetails] = useState([]);
    const getInvoiceDetails = () => {
        setIsLoading(true);
        let param = {};
        let apiData = [];
        param = {
            Bil_doc: inputFieldVal.invoiceNumber,
        };
        api
            .post("/getInvoiceDetails", param)
            .then((res) => {
                console.log("Invoice Details", res.data);
                setSampleTableData(res.data);
                apiData.push(res.data);
                apiData = apiData.flat(); // to convert 2D into 1D
                //

                let invoiceMlfbDetailsTemp = [];
                // apiData.forEach((item) => {
                for (let i = 0; i < apiData.length; i++) {
                    let sampleObj = {
                        InvoicePosition: "",
                        MLFB: "",
                        Quantity: "",
                        OrderNo: "",
                        Item: "",
                        BSS: "",
                        BU: "",
                        AffectedMLFB: "",
                        AffectedQuantity: "",
                        OrderItem: "",
                    };

                    sampleObj.InvoicePosition = inputFieldVal.invoiceNumber;
                    // sampleObj.InvoiceId = "";
                    sampleObj.MLFB = apiData[i].mlfb;
                    sampleObj.Quantity = apiData[i].qty;
                    sampleObj.OrderNo = apiData[i].ord_no;
                    sampleObj.Item = apiData[i].bil_item;
                    sampleObj.BSS = apiData[i].bss;
                    sampleObj.BU = apiData[i].bu;
                    sampleObj.AffectedMLFB =
                        apiData[i].AffectedMLFB == undefined ? "" : apiData[i].AffectedMLFB;
                    sampleObj.AffectedQuantity =
                        apiData[i].AffectedQuantity == undefined
                            ? ""
                            : apiData[i].AffectedQuantity;
                    sampleObj.OrderItem = apiData[i].item;
                    invoiceMlfbDetailsTemp.push(sampleObj);
                }
                // });
                console.log("invoiceMlfbDetails", invoiceMlfbDetails);
                // setSampleTableData(invoiceMlfbDetails);
                setInvoiceMlfbDetails(invoiceMlfbDetailsTemp);

                let _lrNo = "";
                let _lrDate = "";
                if (apiData[0].lrno != "" || apiData[0].lrno != undefined) {
                    let lrNo = apiData[0].lrno.trim();
                    _lrDate = lrNo.slice(lrNo.length - 10, lrNo.length);
                    _lrNo = lrNo.slice(0, lrNo.length - 10);

                    // console.log(extractedDate);
                }

                setInputFieldVal((inputFieldVal) => ({
                    ...inputFieldVal,
                    customer: apiData[0].name,
                    division: apiData[0].division,
                    transporterName: apiData[0].transporter,
                    lrNo: _lrNo,
                    lrDate: _lrDate,
                    invoiceDetails: apiData,
                }));

                setShowTable(true);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getMasterData();
        deleteTempAttchments(userDetails.loginId);
    }, []);

    const testRef = useRef(null);
    const scrollToElement = () => testRef.current.scrollIntoView();

    const multipleFileColumns = [
        { title: "File Name", field: "filename" },
        {
            title: "Download File",
            width: "10%",
            render: (rowData) => downloadIconButton(rowData),
        },
        { title: "Uploaded By", field: "createdName" },
    ];
    const downloadIconButton = (rowData) => {
        return (
            <Button
                href={rowData.fileUrl}
                color="transparent"
                target="_blank"
                download
            >
                <Tooltip title="Download Attachment">
                    <DownloadIconOutlined />
                </Tooltip>
            </Button>
        );
    };

    return (
        <>
            <OnlinePactNavBar />
            <div>
                <DialogTitle id="responsive-dialog-title">Create Complaint</DialogTitle>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <h3> User Details </h3>
                </Grid>
                <Grid item xs={12} sm={6} className="d-flex jc-flex-end align-end">
                    <h4 className="mandatoryText">**All Fields are Mandatory</h4>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="userName"
                        label="Name"
                        variant="filled"
                        type="text"
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.userName}
                        //onChange={inputFieldsChange}
                        // onBlur={}
                        id="userName"
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="department"
                        label="Department"
                        variant="filled"
                        type="text"
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.department}
                        //onChange={inputFieldsChange}
                        // onBlur={}
                        id="department"
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="salesOffice"
                        label="Sales Office"
                        variant="filled"
                        type="text"
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.salesOffice}
                        //onChange={inputFieldsChange}
                        // onBlur={}
                        id="salesOffice"
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="email"
                        label="Email"
                        variant="filled"
                        type="text"
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.email}
                        //onChange={inputFieldsChange}
                        // onBlur={}
                        id="email"
                    />
                </Grid>
            </Grid>
            <div className="accordionCont borderBtm"></div>
            <h3 className="gridMargin">Complaint Details </h3>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel id="efficiency-label">Reason</InputLabel>
                        <Select
                            name="reason"
                            label="demo-simple-select-helper-label"
                            id="reason"
                            value={inputFieldVal.reason}
                            onChange={inputFieldsChange}
                        >
                            <MenuItem value="">--Select--</MenuItem>

                            {reasonMasterData.map((item, index) => (
                                <MenuItem key={index} value={item.id} name={item.reasonName}>
                                    {item.reasonName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel id="efficiency-label">Dispatch Center</InputLabel>
                        <Select
                            name="dispatchCenter"
                            label="demo-simple-select-helper-label"
                            id="dispatchCenter"
                            value={inputFieldVal.dispatchCenter}
                            onChange={inputFieldsChange}
                        >
                            <MenuItem value="">--Select--</MenuItem>

                            {dispatchMasterData.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.id}
                                    name={item.dispatchCentreName}
                                >
                                    {item.dispatchCentreName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <div className="multiEmailText">
                        Enter SE Email (Enter only valid mails)
                    </div>
                    <div id="multiEmailCont">
                        <ReactMultiEmail
                            // placeholder="Input your SE Email Address"
                            emails={seMultiEmails}
                            onChange={(seMultiEmails) => {
                                setSeMultiEmails([...seMultiEmails]);
                            }}
                            getLabel={(email, index, removeEmail) => {
                                return (
                                    <div data-tag key={index}>
                                        {email}
                                        <span data-tag-handle onClick={() => removeEmail(index)}>
                                            ×
                                        </span>
                                    </div>
                                );
                            }}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="remarks"
                        label="Remarks"
                        variant="filled"
                        type="text"
                        multiline="true"
                        minRows="5"
                        maxRows="5"
                        inputProps={{
                            maxlength: 500,
                        }}
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.remarks}
                        onChange={inputFieldsChange}
                        // onBlur={}
                        id="remarks"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} className="gridMargin">
                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel id="efficiency-label">
                            {" "}
                            Select Reference Number
                        </InputLabel>
                        <Select
                            name="referenceNumber"
                            label="demo-simple-select-helper-label"
                            id="referenceNumber"
                            value={inputFieldVal.referenceNumber}
                            onChange={inputFieldsChange}
                        >
                            <MenuItem value="">--Select--</MenuItem>

                            {referenceTypeData.map((item, index) => (
                                <MenuItem key={index} value={item.id} name={item.value}>
                                    {item.value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="gridMargin">
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        disabled={inputFieldVal.referenceNumber != 1 ? true : false}
                        name="invoiceNumber"
                        label="Invoice No"
                        variant="filled"
                        type="text"
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.invoiceNumber}
                        onChange={inputFieldsChange}
                        // onBlur={}
                        id="invoiceNumber"
                    />
                </Grid>

                {inputFieldVal.referenceNumber == 1 && (
                    <>
                        {" "}
                        <Grid item xs={6} sm={2} md={1}>
                            <Button
                                className="pt-button--secondary bulk-upload-submit-btn"
                                type="button"
                                onClick={searchInvoice}
                            >
                                Search
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={2} md={2}>
                            <Button
                                className="pt-button--primary"
                                type="button"
                                onClick={scrollToElement}
                            >
                                Scroll To Table
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>

            <Grid container spacing={2} className="gridMargin">
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        disabled
                        name="customer"
                        label="Customer"
                        variant="filled"
                        type="text"
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.customer}
                        onChange={inputFieldsChange}
                        // onBlur={}
                        id="customer"
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        disabled
                        name="division"
                        label="Division"
                        variant="filled"
                        type="text"
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.division}
                        // onChange={inputFieldsChange}
                        // onBlur={}
                        id="division"
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        disabled
                        name="transporterName"
                        label="Transporter Name"
                        variant="filled"
                        type="text"
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.transporterName}
                        // onChange={inputFieldsChange}
                        // onBlur={}
                        id="transporterName"
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        disabled
                        name="lrNo"
                        label="LR NO"
                        variant="filled"
                        type="text"
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.lrNo}
                        // onChange={inputFieldsChange}
                        // onBlur={}
                        id="lrNo"
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        disabled
                        name="lrDate"
                        label="Select LR Date"
                        variant="filled"
                        type="text"
                        // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
                        value={inputFieldVal.lrDate}
                        // onChange={inputFieldsChange}
                        // onBlur={}
                        id="lrDate"
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <form className="file-upload-form">
                        <TextField
                            type="file"
                            inputProps={{
                                multiple: true,
                            }}
                            label="Upload File"
                            variant="filled"
                            className="file-upload"
                            name="fileUpload"
                            onChange={updateExcelHandler}
                            key={inputFileValue}
                        />
                    </form>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Button
                        className="pt-button--secondary bulk-upload-submit-btn"
                        type="button"
                        disabled={selectedFile ? false : true}
                        onClick={onSubmit}
                    >
                        Upload
                    </Button>
                    <span className="mandatoryText fileSize">Maximum size 20mb</span>
                </Grid>
            </Grid>

            {fileData.length > 0 ? (
                <Accordion className={classes.accordionMargin}>
                    <AccordionSummary
                        className={classes.accordioncolor}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h3>Attachments</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MaterialTable
                            // title={"Attachments"}
                            columns={multipleFileColumns}
                            data={fileData}
                            icons={tableIconsInputScreen}
                            //isLoading={isLoader}
                            options={{
                                headerStyle: tableHeaderStyle,
                                draggable: false,
                                pageSize: 5,
                                showTitle: false,
                                pageSizeOptions: [5, 10, 25, 50],
                                paginationPosition: "bottom",
                                //paging: false,
                                maxBodyHeight: "600px",
                                sorting: true,
                            }}
                            editable={{
                                onRowDelete: (oldTableData) =>
                                    new Promise((resolve, reject) => {
                                        deleteAttachment(oldTableData, resolve, reject);
                                    }),
                            }}
                        />
                    </AccordionDetails>
                </Accordion>
            ) : (
                <></>
            )}

            {/* {fileData.length > 0 ? (
        <MaterialTable
          title={"Attachments"}
          columns={multipleFileColumns}
          data={fileData}
          icons={tableIconsInputScreen}
          //isLoading={isLoader}
          options={{
            headerStyle: tableHeaderStyle,
            draggable: false,
            pageSize: 5,
            pageSizeOptions: [5, 10, 25, 50],
            paginationPosition: "bottom",
            //paging: false,
            maxBodyHeight: "600px",
            sorting: true,
          }}
          editable={{
            onRowDelete: (oldTableData) =>
              new Promise((resolve, reject) => {
                deleteAttachment(oldTableData, resolve, reject);
              }),
          }}
        />
      ) : (
        <></>
      )} */}

            <Grid container spacing={2} className="gridMargin">
                <Grid
                    container
                    spacing={2}
                    className="jc-center d-flex mt-1 buttonContainer"
                >
                    <Grid item xs={10} sm={4} md={3}>
                        <Button
                            fullWidth
                            onClick={saveComplaint}
                            className="pt-button--primary mt-1"
                        >
                            Save
                        </Button>
                    </Grid>
                    <Grid item xs={10} sm={4} md={3}>
                        <Button
                            fullWidth
                            onClick={resetComplaintForm}
                            className="pt-button--secondary mt-1"
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <div ref={testRef}>
                {showTable == true ? (
                    <Grid xs={12} className="resultMatTable">
                        <MaterialTable
                            title="User List"
                            columns={columns}
                            data={invoiceMlfbDetails}
                            icons={tableIconsInputScreen}
                            //isLoading={isLoader}
                            options={tableOptionsUser}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            const dataUpdate = [...invoiceMlfbDetails];
                                            const index = oldData.tableData.id;
                                            dataUpdate[index] = newData;
                                            setInvoiceMlfbDetails([...dataUpdate]);

                                            setInputFieldVal((inputFieldVal) => ({
                                                ...inputFieldVal,
                                                invoiceDetails: dataUpdate,
                                            }));

                                            console.log("Updated data", dataUpdate);
                                            resolve();
                                        }, 1000);
                                    }),
                            }}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
        </>
    );
}
