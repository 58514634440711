import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import {
  Grid,
  makeStyles,
  useTheme,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";
import "./ComplaintsList.scss";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function ComplaintsList() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <Grid container className="complaintsListPage">
        <Grid item xs={12} className="pageTitle">
          <h2>Complaints List</h2>
        </Grid>

        <Grid item xs={12}>
          {/* <Grid item xs={3} md={3}><h1>Hell0</h1></Grid> */}

          <Grid item xs={12}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label="Item One"
                  {...bindHover(popupState)}
                  {...a11yProps(0)}
                >
                  <Button></Button>
                </Tab>
                <Tab label="Item 2" {...a11yProps(1)} />
                <Tab label="Item 3" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Menu
                  {...bindMenu(popupState)}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                >
                  <MenuItem onClick={popupState.close} className="op-list-item">
                    <h3>Create</h3>
                  </MenuItem>
                  <MenuItem onClick={popupState.close} className="op-list-item">
                    <h3>Modify</h3>
                  </MenuItem>
                  <MenuItem onClick={popupState.close} className="op-list-item">
                    <h3>Probation Extention</h3>
                  </MenuItem>
                </Menu>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                Item Two
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                Item Three
              </TabPanel>
            </SwipeableViews>
          </Grid>

          {/* <Grid item xs={3} md={3}><h1>Hell0</h1></Grid> */}
        </Grid>
      </Grid>
    </>
  );
}
