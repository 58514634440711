import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../Screens/Home/Home";
// import OrderList from "../Screens/OrderList/OrderList";
// import OrderInput from "../Screens/OrderInput/InputRoot";
// import Uploads from "../Screens/Uploads/Uploads";
import UserManagement from "../Screens/Administrator/UserManagement/UserManagement";
import ComplaintsList from "../Screens/ComplaintsList/ComplaintsList";
import OnlinePact from "../Screens/Sales/OnlinePact/OnlinePact";
import OnlinePactHome from "../Screens/Sales/OnlinePact/OP_Home/OP_HomePage";

import OPCHPAppointmentCreate from "../Screens/Sales/OnlinePact/OP_CHP_Appointment/OP_CHP_Appointment_Create";
import OPCHPAppointmentModify from "../Screens/Sales/OnlinePact/OP_CHP_Appointment/OP_CHP_Appointment_Modify";
import OPCHPAppointmentProbationExtention from "../Screens/Sales/OnlinePact/OP_CHP_Appointment/OP_CHP_Appointment_ProbationExtention";
import OPCHPAppointmentMandatoryDetails from "../Screens/Sales/OnlinePact/OP_CHP_Appointment/OP_CHP_Appointment_MandatoryDetailsCHPA";
import OPCHPAppointmentApproval from "../Screens/Sales/OnlinePact/OP_CHP_Appointment/OP_CHP_Appointment_Approval";

import OPCHPConfirmationCreate from "../Screens/Sales/OnlinePact/OP_CHP_Confirmation/OP_CHP_Confirmation_Create";
import OPCHPConfirmationApproval from "../Screens/Sales/OnlinePact/OP_CHP_Confirmation/OP_CHP_Confirmation_Approval";
import OPCHPConfirmationCHPTermination from "../Screens/Sales/OnlinePact/OP_CHP_Confirmation/OP_CHP_Confirmation_CHPTermination";

import OPCHPTerminationCreate from "../Screens/Sales/OnlinePact/OP_CHP_Termination/OP_CHP_Termination_Create";
import OPCHPTerminationApproval from "../Screens/Sales/OnlinePact/OP_CHP_Termination/OP_CHP_Termination_Approval";
import OPReportsReports from "../Screens/Sales/OnlinePact/OP_Reports/OP_Reports_Reports";
import OPAdminAdmin from "../Screens/Sales/OnlinePact/OP_Admin/OP_Admin_Admin";
import CreateComplaint from "../Screens/Sales/OnlinePact/CreateComplaint/Create_Complaint";
import CloseComplaint from "../Screens/Sales/OnlinePact/CloseComplaint/Close_Complaint";
import CloseComplaintConfirmation from "../Screens/Sales/OnlinePact/CloseComplaint/Close_Complaint_Confirmation";
import Reports from "../Screens/Sales/OnlinePact/Reports/Reports";
import CloseComplaintRoot from "../Screens/Sales/OnlinePact/CloseComplaint/CloseComplaintRoot";
import NewHome from "../Screens/Home/NewHome";
import DetailedCompReport from "../Screens/Sales/OnlinePact/Reports/DetailedCompReport";
import EscalateComplaint from "../Screens/Sales/OnlinePact/EscalateComplaint/Escalate_Complaint";
import EscalateComplaintRoot from "../Screens/Sales/OnlinePact/EscalateComplaint/EscalateComplaintRoot";
import EscalateConfirmation from "../Screens/Sales/OnlinePact/EscalateComplaint/EscalateConfirmation";
import DelegateComplaint from "../Screens/Sales/OnlinePact/DelegateComplaint/DelegateComplaint";
import DelegateComplaintRoot from "../Screens/Sales/OnlinePact/DelegateComplaint/DelegateComplaintRoot";
import DelegateUser from "../Screens/Sales/OnlinePact/DelegateComplaint/DelegateUser";
import DispatchMaster from "../Screens/Administrator/DispatchCenterMaster/DispatchCenterMaster";
import ReportRoot from "../Screens/Sales/OnlinePact/Reports/ReportRoot";
import UserManagementNew from "../Screens/Administrator/UserManagement/UserManagementNew";

export const MasterRoute = (
    <Switch>
        <Redirect from="/?code*" to="/" />
        <Route exact path="/" render={() => <NewHome />} />
        <Route exact path="/NewHome" render={() => <NewHome />} />
        <Route exact path="/userManagement" render={() => <UserManagementNew />} />
        <Route
            exact
            path="/dispatchCenterMaster"
            render={() => <DispatchMaster />}
        />
        {/* <Route exact path="/complaintList" render={() => <ComplaintsList />} /> */}
        <Route exact path="/complaintList" render={() => <OnlinePactHome />} />

        <Route exact path="/createcomplaint" render={() => <CreateComplaint />} />
        <Route exact path="/closecomplaint" render={() => <CloseComplaint />} />
        <Route exact path="/reports" render={() => <Reports />} />
        <Route exact path="/detailedReport" render={() => <ReportRoot />} />
        <Route
            exact
            path="/escalatecomplaint"
            render={() => <EscalateComplaint />}
        />
        <Route
            exact
            path="/closecomplaintroot"
            render={() => <CloseComplaintRoot />}
        />
        <Route
            exact
            path="/escalatecomplaintroot"
            render={() => <EscalateComplaintRoot />}
        />
        <Route
            exact
            path="/escalateconfirmation"
            render={() => <EscalateConfirmation />}
        />
        <Route
            exact
            path="/closecomplaintdetail"
            render={() => <CloseComplaintConfirmation />}
        />
        <Route
            exact
            path="/DelegateComplaint"
            render={() => <DelegateComplaint />}
        />
        <Route
            exact
            path="/DelegateComplaintRoot"
            render={() => <DelegateComplaintRoot />}
        />
        <Route exact path="/DelegateUser" render={() => <DelegateUser />} />
    </Switch>
);
