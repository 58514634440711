import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Grid,
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
    TextField,
    makeStyles,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import OnlinePactNavBar from "../OnlinePactNavBar";
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../../../Utilities/Utility";
import { tableOptions } from "../../../../Themes/LightTheme";
import {
    DisplayMultipleAttachment,
    getAttachments,
} from "../../../../components/CustomComponents/CustomComponents";
import Loader from "../../../../components/Loader/Loader";
import SnackbarCustom from "../../../../components/Snackbar/Snackbar";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(3),
    },
    button: {
        margin: theme.spacing(1, 1, 0, 0),
    },
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function DetailedCompReport(props) {
    const reportRowData = useSelector(
        (state) => state.detReportsReducer.detReportsData
    );
    const classes = useStyles();
    const history = useHistory();
    const [allAttachments, setAllAttachments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        console.log("Row Dataa", reportRowData);
        getAttachmentsFromID();
    }, []);

    function convertDate(dateStrArg) {
        if (dateStrArg != null) {
            const dateStr = new Date(dateStrArg);
            const options = {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true,
            };
            const retDateStr = dateStr.toLocaleDateString("en-US", options);
            return retDateStr;
        }
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const getSteps = () => {
        return ["Complaint Details", "MLFB Details"];
    };
    const steps = getSteps();

    const handleReset = () => {
        setActiveStep(0);
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep == 0) history.goBack();
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    async function getAttachmentsFromID() {
        setIsLoading(true);
        let complaintNor = { ComplaintNumber: reportRowData.complaintNumber };
        const response = await getAttachments(complaintNor);
        if (response.status == 200) {
            setAllAttachments([
                ...response.data.tempAttachmentDetails,
                ...response.data.closeAttachmentDetails,
            ]);
            setIsLoading(false);
        } else {
            handleSnackOpen("Error in fetching Data !!", "error");
            setIsLoading(false);
        }
    }

    const closeCompDtlColumn = [
        { title: "Invoice Position", field: "item" },
        { title: "MLFB", field: "mlfb" },
        { title: "Quantity", field: "quantity" },
        { title: "Order No", field: "orderNo" },
        { title: "Order Item", field: "orderItem" },
        { title: "BSS", field: "bss" },
        { title: "BU", field: "bu" },
        { title: "Affected MLFB", field: "enteredMlfb" },
        { title: "Affected Qty", field: "enteredQuantity" },
    ];

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="complaintNumber"
                                    label="Complaint Number"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.complaintNumber}
                                    // onChange={}
                                    id="complaintNumber"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="name"
                                    label="Name"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.name}
                                    // onChange={}
                                    id="name"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="department"
                                    label="Department"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.departmentName}
                                    // onChange={}
                                    id="department"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="salesOffice"
                                    label="Sales Office"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.salesOffice}
                                    // onChange={}
                                    id="salesOffice"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="email"
                                    label="Email"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.email}
                                    // onChange={}
                                    id="email"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="reason"
                                    label="Reason"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.reasonName}
                                    // onChange={}
                                    id="reason"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="seEmail"
                                    label="SE Email"
                                    variant="filled"
                                    type="text"
                                    multiline="true"
                                    minRows="4"
                                    maxRows="4"
                                    value={reportRowData.seemail}
                                    // onChange={}
                                    id="seEmail"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="transporterName"
                                    label="Transporter Name"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.transporter}
                                    // onChange={}
                                    id="transporterName"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="referenceNumber"
                                    label="Reference Number"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.referenceName}
                                    // onChange={}
                                    id="referenceNumber"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="createdBy"
                                    label="Created by"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.name}
                                    // onChange={}
                                    id="createdBy"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="customer"
                                    label="Customer"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.customerName}
                                    // onChange={}
                                    id="customer"
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="createdDate"
                                    label="Created Date"
                                    variant="filled"
                                    type="text"
                                    value={convertDate(reportRowData.createdDate)}
                                    // onChange={}
                                    id="createdDate"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="dispatchCenter"
                                    label="Dispatch Center"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.dispatchCenterName}
                                    // onChange={}
                                    id="dispatchCenter"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="status"
                                    label="Status"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.status}
                                    // onChange={}
                                    id="status"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="remarks"
                                    label="Remarks"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.remarks}
                                    // onChange={}
                                    id="remarks"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="closingRemarks"
                                    label="Closing Remarks"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.closingRemarks}
                                    // onChange={}
                                    id="closingRemarks"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="userName"
                                    label="Closed By"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.userName}
                                    // onChange={}
                                    id="userName"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="closedDate"
                                    label="Closed Date"
                                    variant="filled"
                                    type="text"
                                    value={convertDate(reportRowData.closedDate)}
                                    // onChange={}
                                    id="closedDate"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="acceptance"
                                    label="Acceptance"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.acceptance}
                                    // onChange={}
                                    id="acceptance"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    className=""
                                    fullWidth
                                    disabled
                                    name="escalatedBy"
                                    label="Escalated By"
                                    variant="filled"
                                    type="text"
                                    value={reportRowData.escalatedByName}
                                    // onChange={}
                                    id="escalatedBy"
                                />
                            </Grid>
                        </Grid>

                        {allAttachments.length > 0 ? (
                            <DisplayMultipleAttachment tableData={allAttachments} />
                        ) : (
                            <></>
                        )}
                    </>
                );
            case 1:
                return (
                    <MaterialTable
                        title="User List"
                        columns={closeCompDtlColumn}
                        data={reportRowData.invoiceDetails}
                        icons={tableIconsInputScreen}
                        //isLoading={isLoader}
                        options={tableOptions}
                    />
                );
            default:
                return "Unknown stepIndex";
        }
    }

    return (
        <>
            <OnlinePactNavBar />

            <div className="stepperCont">
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                All steps completed
                            </Typography>
                            <Button onClick={handleReset}>Reset</Button>
                        </div>
                    ) : (
                        <>
                            <Typography className={classes.instructions}>
                                {getStepContent(activeStep)}
                            </Typography>
                            <Grid
                                container
                                spacing={2}
                                className="jc-center d-flex mt-1 gridMargin"
                            >
                                <Grid item xs={10} sm={4} md={2}>
                                    <Button
                                        fullWidth
                                        // disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className="pt-button--secondary mt-1"
                                    >
                                        Back
                                    </Button>
                                </Grid>
                                {activeStep === 0 ? (
                                    <Grid item xs={10} sm={4} md={2}>
                                        <Button
                                            fullWidth
                                            // disabled={activeStep === 1}
                                            onClick={handleNext}
                                            className="pt-button--primary mt-1"
                                        >
                                            Next
                                        </Button>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </>
                    )}
                </div>
            </div>
            {/* 
      <div>
        <DialogTitle id="responsive-dialog-title">
          Complaint Details
        </DialogTitle>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="complaintNumber"
              label="Complaint Number"
              variant="filled"
              type="text"
              value={reportRowData.complaintNumber}
              // onChange={}
              id="complaintNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="name"
              label="Name"
              variant="filled"
              type="text"
              value={reportRowData.name}
              // onChange={}
              id="name"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="department"
              label="Department"
              variant="filled"
              type="text"
              value={reportRowData.departmentName}
              // onChange={}
              id="department"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="salesOffice"
              label="Sales Office"
              variant="filled"
              type="text"
              value={reportRowData.salesOffice}
              // onChange={}
              id="salesOffice"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="email"
              label="Email"
              variant="filled"
              type="text"
              value={reportRowData.email}
              // onChange={}
              id="email"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="reason"
              label="Reason"
              variant="filled"
              type="text"
              value={reportRowData.reasonName}
              // onChange={}
              id="reason"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="seEmail"
              label="SE Email"
              variant="filled"
              type="text"
              multiline="true"
              minRows="4"
              maxRows="4"
              value={reportRowData.seemail}
              // onChange={}
              id="seEmail"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="transporterName"
              label="Transporter Name"
              variant="filled"
              type="text"
              value={reportRowData.transporter}
              // onChange={}
              id="transporterName"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="referenceNumber"
              label="Reference Number"
              variant="filled"
              type="text"
              value={reportRowData.referenceName}
              // onChange={}
              id="referenceNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="createdBy"
              label="Created by"
              variant="filled"
              type="text"
              value={reportRowData.name}
              // onChange={}
              id="createdBy"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="customer"
              label="Customer"
              variant="filled"
              type="text"
              value={reportRowData.customerName}
              // onChange={}
              id="customer"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="createdDate"
              label="Created Date"
              variant="filled"
              type="text"
              value={convertDate(reportRowData.createdDate)}
              // onChange={}
              id="createdDate"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="dispatchCenter"
              label="Dispatch Center"
              variant="filled"
              type="text"
              value={reportRowData.dispatchCenterName}
              // onChange={}
              id="dispatchCenter"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="status"
              label="Status"
              variant="filled"
              type="text"
              value={reportRowData.status}
              // onChange={}
              id="status"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="remarks"
              label="Remarks"
              variant="filled"
              type="text"
              value={reportRowData.remarks}
              // onChange={}
              id="remarks"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="closingRemarks"
              label="Closing Remarks"
              variant="filled"
              type="text"
              value={reportRowData.closingRemarks}
              // onChange={}
              id="closingRemarks"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="userName"
              label="Closed By"
              variant="filled"
              type="text"
              value={reportRowData.userName}
              // onChange={}
              id="userName"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="closedDate"
              label="Closed Date"
              variant="filled"
              type="text"
              value={convertDate(reportRowData.closedDate)}
              // onChange={}
              id="closedDate"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="acceptance"
              label="Acceptance"
              variant="filled"
              type="text"
              value={reportRowData.acceptance}
              // onChange={}
              id="acceptance"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="escalatedBy"
              label="Escalated By"
              variant="filled"
              type="text"
              value={reportRowData.escalatedBy}
              // onChange={}
              id="escalatedBy"
            />
          </Grid>
        </Grid>
      </div>

      <div>
        <DialogTitle id="responsive-dialog-title">Invoice Details</DialogTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="invoiceNumber"
              label="Invoice Number"
              variant="filled"
              type="text"
              value={reportRowData.invoicePosition}
              // onChange={}
              id="invoiceNumber"
            />
          </Grid>

          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="Invoice Position"
              label="Invoice Position"
              variant="filled"
              type="text"
              value={reportRowData.item}
              // onChange={}
              id="Invoice Position"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="Order No"
              label="Order No"
              variant="filled"
              type="text"
              value={reportRowData.orderNo}
              // onChange={}
              id="Order No"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="Order Item"
              label="Order Item"
              variant="filled"
              type="text"
              value={reportRowData.orderItem}
              // onChange={}
              id="Order Item"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="Quantity"
              label="Quantity"
              variant="filled"
              type="text"
              value={reportRowData.quantity}
              // onChange={}
              id="Quantity"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="MLFB"
              label="MLFB"
              variant="filled"
              type="text"
              value={reportRowData.mlfb}
              // onChange={}
              id="MLFB"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="enteredQuantity"
              label="Entered Quantity"
              variant="filled"
              type="text"
              value={reportRowData.enteredQuantity}
              // onChange={}
              id="enteredQuantity"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="enteredMlfb"
              label="Entered MLFB"
              variant="filled"
              type="text"
              value={reportRowData.enteredMlfb}
              // onChange={}
              id="enteredMlfb"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="bu"
              label="BU"
              variant="filled"
              type="text"
              value={reportRowData.bu}
              // onChange={}
              id="bu"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="bss"
              label="BSS"
              variant="filled"
              type="text"
              value={reportRowData.bss}
              // onChange={}
              id="bss"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="division"
              label="Division"
              variant="filled"
              type="text"
              value={reportRowData.division}
              // onChange={}
              id="division"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="lrno"
              label="LR No"
              variant="filled"
              type="text"
              value={reportRowData.lrno}
              // onChange={}
              id="lrno"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              className=""
              fullWidth
              disabled
              name="lrdate"
              label="LR Date"
              variant="filled"
              type="text"
              value={reportRowData.lrdate}
              // onChange={}
              id="lrdate"
            />
          </Grid>
        </Grid>
      </div> */}

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />

            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
        </>
    );
}
