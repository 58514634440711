import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import "./LoginRegister.scss";
import SiemensLogo from "../../assets/images/siemens-logo-petrol.svg";
import LoginRegisterTabs from "./LoginRegisterTabs";
import { AppBar, Tabs, Tab, Box, Button, TextField } from "@material-ui/core";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: "rgba(0, 204, 204b, .05)",
        // backdropFilter: "blur(1px)",
        minHeight: "111vh",
        overflow: "auto",
    },
    paper: {
        padding: theme.spacing(2),
    },
    errorMessage: {
        color: "var(--red)",
        fontSize: "16px",
    },
    timerDetails: {
        backgroundColor: "var(--bg-green-2)",
    },
    forgotPwd: {
        textAlign: "center",
        textDecoration: "underline",
    },
    loginTabs: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "15px",
        //backgroundColor: "rgba(0, 204, 204, .05)",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: "0px",
    },
}));

export default function LoginRoot() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const auth = useAuth();
    const { instance } = useMsal();

    const redirectToMyId = () => {
        getUserDetails();
    }

    function azureADLogin() {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.log(e);
        });
    }

    const getUserDetails = async () => {
        // const response
        await fetch('Login/GetUser', {
            mode: 'no-cors',
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        },
        ).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    // console.log(json);
                    const data = json;
                    //setUser(JSON.stringify(data));
                    //setUserRoleID(data["roleId"]);
                    //setUserFirstName(data["firstName"]);
                    //setUserLastName(data["lastName"]);
                    //setUserId(data["id"]);

                    //                 
                    //<Route path='/user-management' component={UserManagement} />                

                });
            }
            //else
            // window.location.reload();
        });

    }

    return (
        <div className="bg-login-register">
            <div className={classes.root}>
                <Container>
                    <Grid container className="pt-lr-mobileContainer">
                        <Grid item xs={12}>
                            <div className="pt-login-logo-block">
                                <img
                                    src={SiemensLogo}
                                    className="official-logo"
                                    alt="Siemens"
                                />
                            </div>
                        </Grid>
                        <Grid container className="login-container">
                            <Grid
                                item
                                xs={12}
                                sm={10}
                                md={6}
                                className="pt-lr--project-block"
                            >
                                <div className="pt-lr--project">
                                    <p className="pt-lr-project-name">RECO</p>
                                    <span className="pt-lr--project-description subtitle">
                                        <span className="pt-subtitle">RE</span>turns and <span className="pt-subtitle">CO</span>mplaints
                                    </span>
                                    <span className="pt-lr--project-description">
                                        User Friendly tool to address customer complaints effectively & diligently.
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className="login-container">
                            <Grid item xs={12} sm={10} md={6} className="pt-lr-master-block">
                                <div className="pt-lr-block myid-login-text">
                                    <div className="pt-login-siemens_user">
                                        <h3 className="pt-login-siemens-login">Siemens Employee Login </h3>
                                        {/*  <span>If you are a Siemens Employee, login via MyID</span>*/}
                                        <div className="d-flex jc-center myid-login-btn-block">
                                            <Link to="/">
                                                <Button
                                                    className="pt-button--hero myid-login-btn"
                                                    id="myIDLoginBtn"
                                                    onClick={azureADLogin}
                                                >
                                                    <span>AD Login</span>
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>

        // <div className="bg-login-register">
        //   <Container>
        //     <Grid container xs={12}>
        //       <Grid item xs={12}>
        //         <div className="ssiat-login-logo-block">
        //           <img src={SiemensLogo} className="official-logo" alt="Siemens" />
        //         </div>
        //       </Grid>

        //       <Grid container className="ssiat-lr-mobileContainer">
        //         <Grid
        //           item
        //           xs={12}
        //           sm={10}
        //           md={6}
        //           className="ssiat-lr--project-block"
        //         >
        //           <div className="ssiat-lr--project">
        //             <p className="ssiat-lr-project-name">RECOTOOL</p>
        //             <span className="ssiat-lr--project-description">
        //               Description....
        //             </span>
        //           </div>
        //         </Grid>
        //       </Grid>

        //       <Grid container className="ssiat-lr-mobileContainer">
        //         <Grid
        //           item
        //           xs={12}
        //           sm={10}
        //           md={6}
        //           className="ssiat-lr--master-block"
        //         >
        //           {/* <LoginRegisterTabs /> */}
        //           <h3>MYID</h3>
        //         </Grid>
        //       </Grid>
        //     </Grid>
        //   </Container>
        // </div>
    );
}
