import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
    TextField,
    IconButton,
    Tooltip,
    Zoom,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
} from "@material-ui/core";
import "./Close_Complaint.scss";
import { ContactSupportOutlined } from "@material-ui/icons";
import {
    baseApiURL,
    tableIconsInputScreen,
} from "../../../../Utilities/Utility";
import { tableHeaderStyle } from "../../../../Themes/LightTheme";
import MaterialTable from "material-table";
import EditIconOutlined from "@material-ui/icons/Edit";
import DownloadIconOutlined from "@material-ui/icons/GetApp";
import CloseComplaintConfirmation from "./Close_Complaint_Confirmation";
import Loader from "../../../../components/Loader/Loader";
import axios from "axios";
import { render } from "@testing-library/react";
import { useHistory } from "react-router-dom";
import {
    closingComDetailsAction,
    complaintDetailDataAction,
} from "../../../../Redux/Actions/actions";
import OnlinePactNavBar from "../OnlinePactNavBar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { deleteTempAttchments } from "../../../../components/CustomComponents/CustomComponents";

const tableOptionsUser = {
    headerStyle: tableHeaderStyle,
    showTitle: false,
    draggable: false,
    pageSize: 5,
    exportButton: false,
    // search: true,
    // add: true,
    pageSizeOptions: [5, 10, 25, 50],
    paginationPosition: "bottom",
    //paging: false,
    maxBodyHeight: "600px",
    sorting: true,
};

export default function CloseComplaint() {
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        getReasonMaster();
        getDispatchCentreMaster();
        getAllComplaintsList();
        getOfficeMaster();
        getCustomerMaster();
        getComplaintNumber();
        dispatch(complaintDetailDataAction(""));
        dispatch(
            closingComDetailsAction({
                status: "",
                remarks: "",
            })
        );
        deleteTempAttchments(userDetails.loginId);
    }, []);

    const api = axios.create({
        baseURL: "CloseComplaint",
    });
    const reportsAPI = axios.create({
        baseURL: "api/Reports",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [reasonMasterData, setReasonMasterData] = useState([]);
    const getReasonMaster = () => {
        setIsLoading(true);
        api
            .get("/getReasonmaster")
            .then((res) => {
                setReasonMasterData(res.data);
                console.log("Reason Master", res.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
                setIsLoading(false);
            });
    };
    const [dispatchMasterData, setDispatchMasterData] = useState([]);
    const getDispatchCentreMaster = () => {
        api
            .get("/getDispatchcentremaster")
            .then((res) => {
                console.log("Dispatch Master", res.data);
                setDispatchMasterData(res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const [officeMasterData, setOfficeMasterData] = useState([]);
    const getOfficeMaster = () => {
        api
            .get("/getOfficemaster")
            .then((res) => {
                console.log("Office Master", res.data);
                setOfficeMasterData(res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const [customerMasterData, setCustomerMasterData] = useState([]);
    const getCustomerMaster = () => {
        reportsAPI
            .get("/getCustomerMaster")
            .then((res) => {
                console.log("Customer Master", res.data);
                setCustomerMasterData(res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };

    const [complaintNumberData, setComplaintNumberData] = useState([]);
    const getComplaintNumber = () => {
        reportsAPI
            .get("/getComplaintNumber/" + userDetails.loginId)
            .then((res) => {
                console.log("Complaint Master", res.data);
                setComplaintNumberData(res.data);
            })
            .catch((error) => {
                console.log("Error in hitting api at page load.", error);
            });
    };
    const [complaintDetailsInput, setComplaintDetailsInput] = useState({
        complaintNoSearchString: "",
        reasonSearchString: "",
        dispatchCenterSearchString: "", // should look up with dept master
        salesOfficeSearchString: "",
        customerSearchString: "",
    });
    const [complaintNoSearchStringUI, setComplaintNoSearchStringUI] = useState(
        complaintDetailsInput.complaintNoSearchString
    );
    const [reasonSearchStringUI, setReasonSearchStringUI] = useState(
        complaintDetailsInput.reasonSearchString
    );
    const [dispatchCenterSearchStringUI, setDispatchCenterSearchStringUI] =
        useState(complaintDetailsInput.dispatchCenterSearchString);
    const [salesOfficeSearchStringUI, setSalesOfficeSearchStringUI] = useState(
        complaintDetailsInput.salesOfficeSearchString
    );
    const [customerSearchStringUI, setCustomerSearchStringUI] = useState(
        complaintDetailsInput.customerSearchString
    );

    const inputFieldsChange = (e) => {
        const fieldVal = e.target.value;
        const fieldName = e.target.name;
        setComplaintDetailsInput((complaintDetailsInput) => ({
            ...complaintDetailsInput,
            [fieldName]: fieldVal,
        }));

        switch (fieldName) {
            case "complaintNoSearchString":
                setComplaintNoSearchStringUI(fieldVal);
                break;
            case "reasonSearchString":
                setReasonSearchStringUI(fieldVal);
                break;
            case "dispatchCenterSearchString":
                setDispatchCenterSearchStringUI(fieldVal);
                break;
            case "salesOfficeSearchString":
                setSalesOfficeSearchStringUI(fieldVal);
                break;
            case "customerSearchString":
                setCustomerSearchStringUI(fieldVal);
                break;
            default:
                break;
        }
    };

    const complaintNorChange = (event, value) => {
        setComplaintDetailsInput((complaintDetailsInput) => ({
            ...complaintDetailsInput,
            complaintNoSearchString: value,
        }));
        setComplaintNoSearchStringUI(value);
    };
    const customerStrChange = (event, value) => {
        setComplaintDetailsInput((complaintDetailsInput) => ({
            ...complaintDetailsInput,
            customerSearchString: value,
        }));
        setCustomerSearchStringUI(value);
    };

    const [canShowMaterialTable, setCanShowMaterialTable] = useState(false);
    const searchComplaint = () => {
        setCanShowMaterialTable(true);

        let searchArgs = {
            ComplaintNumber: complaintDetailsInput.complaintNoSearchString,
            Reason:
                complaintDetailsInput.reasonSearchString != ""
                    ? complaintDetailsInput.reasonSearchString
                    : 0,
            DispatchCenter:
                complaintDetailsInput.dispatchCenterSearchString != ""
                    ? complaintDetailsInput.dispatchCenterSearchString
                    : 0,
            SalesOffice:
                complaintDetailsInput.salesOfficeSearchString != ""
                    ? complaintDetailsInput.salesOfficeSearchString
                    : 0,
            CustomerName: complaintDetailsInput.customerSearchString,
            loginId: userDetails.loginId,
        };

        api
            .post("/GetComplaintList", searchArgs)
            .then(function (response) {
                // handleSnackOpen("Complaint Saved Successfully.", "success");
                // setIsLoading(false);
                setAllOpenComplaints(response.data);
                console.log("Motor Details", response.data);
            })
            .catch(function (error) {
                // handleSnackOpen("Error in saving.", "error");
                setIsLoading(false);
                // console.log(error);
            });
    };
    const ResetSearch = () => {
        console.log("Reset Button Clicked");
        setComplaintDetailsInput({
            complaintNoSearchString: "",
            reasonSearchString: "",
            dispatchCenterSearchString: "",
            salesOfficeSearchString: "",
            customerSearchString: "",
        });
        setComplaintNoSearchStringUI("");
        setReasonSearchStringUI("");
        setDispatchCenterSearchStringUI("");
        setSalesOfficeSearchStringUI("");
        setCustomerSearchStringUI("");
    };

    function renderRefNo(value) {
        if (value == 1) return "Invoice";
        if (value == 2) return "Misc. Document/Reasons";
    }
    const [allOpenComplaints, setAllOpenComplaints] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const getAllComplaintsList = () => {
        setIsTableLoading(true);
        api
            .get("/GetAllOpenComplaint/" + userDetails.loginId)
            .then(function (response) {
                // handleSnackOpen("Complaint Saved Successfully.", "success");
                setIsLoading(false);
                console.log("Complaints List", response.data);
                setAllOpenComplaints(response.data);
                setIsTableLoading(false);
            })
            .catch(function (error) {
                // handleSnackOpen("Error in saving.", "error");
                setIsLoading(false);
                setIsTableLoading(false);
                console.log(error);
            });
    };

    const closeCompColumns = [
        { title: "Complaint No", field: "complaintNumber", editable: "never" },
        { title: "Name", field: "name", editable: "never" },
        { title: "Department", field: "departmentName", editable: "never" },
        { title: "Sales Office", field: "salesOffice", editable: "never" },
        { title: "Email", field: "email", editable: "never" },
        { title: "Reason", field: "reasonName", editable: "never" },
        {
            title: "Dispatch Center",
            field: "dispatchCenterName",
            editable: "never",
        },
        { title: "Customer Name", field: "customerName", editable: "never" },
        {
            title: "Edit",
            width: "10%",
            render: (rowData) => editIconButton(rowData),
        },
        // {
        //   title: "Delete",
        //   width: "10%",
        //   render: (rowData) => downloadIconButton(rowData),
        // },
    ];

    const editIconButton = (rowData) => {
        return (
            <IconButton
                aria-label="EditIconOutlined"
                onClick={() => editIconClick(rowData)}
            >
                <Tooltip title="Edit">
                    <EditIconOutlined />
                </Tooltip>
            </IconButton>
        );
    };

    const downloadIconButton = (rowData) => {
        return (
            // <IconButton
            //   aria-label="DownloadIconOutlined"
            //   onClick={() => console.log("download")}
            // >
            //   <Tooltip title="Download Attachment">
            //     <DownloadIconOutlined />
            //   </Tooltip>
            // </IconButton>

            <Button
                href={rowData.fileUrl}
                color="transparent"
                target="_blank"
                download
            >
                <Tooltip title="Download Attachment">
                    <DownloadIconOutlined />
                </Tooltip>
            </Button>
        );
    };

    function editIconClick(rowData) {
        // <CloseComplaintConfirmation />;
        // navigate("/closecomplaintdetail");
        dispatch(complaintDetailDataAction(rowData));
        history.push("/closecomplaintroot");
    }

    const [sampleData, setSampleData] = useState([
        {
            comp_no: 1,
            roll_no: "9019602907",
            name: "7000",
            department: "mlfb",
            phone_no: "2440",
            sales_no: "O1",
            email: "40285646",
            reference_no: "25",
            invoice_no: "transporter1",
            invoice_position: "09.03.2022",
            mlfb: "123",
            quantity: "456",
            reason: "Mac Failure",
            dispatch_center: "MUM",
            customer: "ABC",
            division: "BDC",
            transporter_name: "Sachin",
            lr_no: "879",
            lr_date: "12/2/2022",
            se_email: "seemail@siemens.com",
            remarks: "Remarks provided",
            attachment: "attachment1",
        },
    ]);

    return (
        <>
            <OnlinePactNavBar />
            <div>
                <DialogTitle id="responsive-dialog-title">Close Complaint</DialogTitle>
            </div>

            <Grid container spacing={2} className="mt-05">
                <Grid item xs={12} sm={4} md={3} className="customerField">
                    <Autocomplete
                        options={complaintNumberData}
                        getOptionLabel={(option) => option}
                        id="debug"
                        clearOnEscape={true}
                        debug
                        value={setComplaintNoSearchStringUI}
                        onChange={complaintNorChange}
                        renderInput={(params) => (
                            <TextField {...params} label="Complaint Number" margin="none" />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel id="efficiency-label">Reason</InputLabel>
                        <Select
                            name="reasonSearchString"
                            label="demo-simple-select-helper-label"
                            id="reasonSearchString"
                            value={reasonSearchStringUI}
                            onChange={inputFieldsChange}
                        >
                            <MenuItem value="">--Select--</MenuItem>

                            {reasonMasterData.map((item, index) => (
                                <MenuItem key={index} value={item.id} name={item.reasonName}>
                                    {item.reasonName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* <TextField
            className=""
            fullWidth
            name="reasonSearchString"
            label="Reason"
            variant="filled"
            type="text"
            // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
            value={reasonSearchStringUI}
            onChange={inputFieldsChange}
            // onBlur={}
            id="reasonSearchString"
          /> */}
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel id="dispatch-center-label">Dispatch Center</InputLabel>
                        <Select
                            name="dispatchCenterSearchString"
                            label="Dispatch Center"
                            id="dispatchCenterSearchString"
                            value={dispatchCenterSearchStringUI}
                            onChange={inputFieldsChange}
                        >
                            <MenuItem value="">--Select--</MenuItem>

                            {dispatchMasterData.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.id}
                                    name={item.dispatchCentreName}
                                >
                                    {item.dispatchCentreName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* <TextField
            className=""
            fullWidth
            name="dispatchCenterSearchString"
            label="Dispatch Center"
            variant="filled"
            type="text"
            // InputProps={{ inputProps: { min: "0", step: "0.01" } }}
            value={dispatchCenterSearchStringUI}
            onChange={inputFieldsChange}
            // onBlur={}
            id="dispatchCenterSearchString"
          /> */}
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel id="efficiency-label">Sales Office</InputLabel>
                        <Select
                            name="salesOfficeSearchString"
                            label="demo-simple-select-helper-label"
                            id="salesOfficeSearchString"
                            value={salesOfficeSearchStringUI}
                            onChange={inputFieldsChange}
                        >
                            <MenuItem value="">--Select--</MenuItem>

                            {officeMasterData.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.officeId}
                                    name={item.officeName}
                                >
                                    {item.officeName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4} md={3} className="customerField">
                    <Autocomplete
                        options={customerMasterData}
                        getOptionLabel={(option) => option}
                        id="debug"
                        clearOnEscape={true}
                        debug
                        value={setCustomerSearchStringUI}
                        onChange={customerStrChange}
                        renderInput={(params) => (
                            <TextField {...params} label="Customer" margin="none" />
                        )}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    {/* <Tooltip
            title="Search"
            TransitionComponent={Zoom}
            enterDelay={100}
            leaveDelay={100}
          >
            <IconButton onClick={searchComplaint}>
              <SearchOutlined />
            </IconButton>
          </Tooltip> */}
                    <Button
                        className="pt-button--secondary bulk-upload-submit-btn"
                        type="button"
                        onClick={searchComplaint}
                        fullWidth
                    >
                        Search
                    </Button>
                </Grid>

                {/* <Grid item xs={12} sm={4} md={3}>
          <Button
            className="pt-button--secondary bulk-upload-submit-btn"
            type="button"
            onClick={ResetSearch}
            fullWidth
          >
            Reset Search
          </Button>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button
            className="pt-button--secondary bulk-upload-submit-btn"
            type="button"
            onClick={getAllComplaintsList}
            fullWidth
          >
            Show All Complaints
          </Button>
        </Grid> */}
            </Grid>

            <div className="tableTitle">
                <h3>Complaints List</h3>
            </div>
            <div className="matTableClass">
                <Grid item xs={12}>
                    <MaterialTable
                        columns={closeCompColumns}
                        data={allOpenComplaints}
                        icons={tableIconsInputScreen}
                        //isLoading={isLoader}
                        options={tableOptionsUser}
                        isLoading={isTableLoading}
                    />
                </Grid>
            </div>

            <Grid container spacing={2}></Grid>

            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
        </>
    );
}
