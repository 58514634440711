import { combineReducers } from "redux";

let tokenInitialState = {
    token: "",
};
const saveTokenReducer = (state = tokenInitialState, action) => {
    switch (action.type) {
        case "saveAccessToken": {
            return {
                token: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let userDataInitialState = {
    userData: {
        gid: "",
        roleId: 0,
        roleName: "",
        userEmail: "",
        userFirstName: "",
        userLastName: "",
        userId: 0,
    },
};
const storeUserDetailsReducer = (
    state = userDataInitialState,
    { type, payLoad }
) => {
    switch (type) {
        case "storeUserData": {
            return {
                userData: {
                    ...payLoad,
                },
            };
        }
        default: {
            return state;
        }
    }
};

let isUserAuthorizedInitialState = false;
const isUserAuthorizedReducer = (
    state = isUserAuthorizedInitialState,
    action
) => {
    switch (action.type) {
        case "isUserAuthorized": {
            return {
                authorized: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};

let userInitialState = {
    gid: "",
};
const authUserReducer = (state = userInitialState, action) => {
    switch (action.type) {
        case "myIDLogin": {
            return {
                gid: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let isUpdateResponse = { response: 0 };
const updateResponseReducer = (state = isUpdateResponse, action) => {
    switch (action.type) {
        case "updatePotential": {
            return { response: action.payLoad + state.response };
        }
        case "updateBudget": {
            return { response: action.payLoad + state.response };
        }
        case "updateManualIndirect": {
            return { response: action.payLoad + state.response };
        }
        default:
            return state;
    }
};

let complaintDetailDataInitialState = {
    complaintDetailData: "",
};
const complaintDetailDataReducer = (
    state = complaintDetailDataInitialState,
    action
) => {
    switch (action.type) {
        case "complaintDetailData": {
            return {
                complaintDetailData: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};

const closingCompDetailsInitialState = {
    status: "",
    remarks: "",
};
const closingCompDetailsReducer = (
    state = closingCompDetailsInitialState,
    action
) => {
    switch (action.type) {
        case "closeDtlObj": {
            return {
                complaintDetailData: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
const escalateRemarksInitialState = {
    remarks: "",
};
const escalateRemarksReducer = (
    state = escalateRemarksInitialState,
    action
) => {
    switch (action.type) {
        case "escalateRem": {
            return {
                escalateRemarks: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};

const detReportsInitialState = {};
const detReportsReducer = (state = detReportsInitialState, action) => {
    switch (action.type) {
        case "detReports": {
            return {
                detReportsData: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
let azureToken = {
    token: "",
};

const saveAzureTokenReducer = (state = azureToken, action) => {
    switch (action.type) {
        case "saveAzureToken": {
            return {
                token: action.payLoad,
            };
        }
        default: {
            return state;
        }
    }
};
export const masterReducer = combineReducers({
    saveTokenReducer: saveTokenReducer,
    storeUserDetailsReducer: storeUserDetailsReducer,
    authUserReducer: authUserReducer,
    updateResponseReducer: updateResponseReducer,
    complaintDetailDataReducer: complaintDetailDataReducer,
    closingCompDetailsReducer: closingCompDetailsReducer,
    detReportsReducer: detReportsReducer,
    escalateRemarksReducer: escalateRemarksReducer,
    isUserAuthorized: isUserAuthorizedReducer,
    saveAzureTokenReducer: saveAzureTokenReducer,
});
