import { Switch, Route } from "react-router-dom";
import LoginRoot from "../Screens/LoginRegister/LoginRoot";
// import ChangePassword from '../Pages/ResetPassword/ChangePassword';
import Home from "../Screens/Home/Home";
import UserManagement from "../Screens/Administrator/UserManagement/UserManagement";

export const LoginRoute = (
  <Switch>
    <Route exact path="/" render={() => <LoginRoot />} />
  </Switch>
);

// export const ResetPasswordRoute = (
//     <Switch>
//         {/* <Redirect from="/" to="/changePassword" /> */}
//         <Route exact path="/" render={() => <ChangePassword />} />
//     </Switch>
// );
